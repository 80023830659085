import LazySizes from 'lazysizes'
import GLightbox from 'glightbox'
import Alpine from 'alpinejs'
import morph from '@alpinejs/morph'
import collapse from '@alpinejs/collapse'
import scrollTo from 'alpinejs-scroll-to'

// Import our CSS
import "@/css/app.css";

window.lightbox = GLightbox({})
window.GLightbox = GLightbox;
window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.plugin(morph)
Alpine.plugin(scrollTo)


import MmenuLight from './components/ui/Mmenu'
import VenveoApplication from "./base/VenveoApplication"
import GalleryModal from "./components/ui/sliders/GalleryModal/GalleryModal";
import Headroom from './components/ui/Headroom'

const application = new VenveoApplication(
    import.meta.glob("./components/**/*.[j|t]s")
);

application.registerComponent('mmenu-light', '#mobile-menu-nav', MmenuLight)
application.registerComponent('headroomjs', 'header', Headroom);
application.registerComponent('slider-gallery-modal', 'body', GalleryModal);

application.registerComponent('slider-product-detail-hero', '[data-slider-product-detail]', './components/ui/sliders/ProductDetailHero/ProductDetailHero.ts');
application.registerComponent('slider-homepage-hero', '[data-slider-herohome]', './components/ui/sliders/ProductHomeHero/ProductHomeHero.ts');
application.registerComponent('slider-testimonial', '[data-slider-testimonial]', './components/ui/sliders/Testimonial/Testimonial.ts');
application.registerComponent('shopify-product-page-app', '#shopify-product-page-app', './components/ui/ShopifyProductPage.ts');
application.registerComponent('shopify-checkout-cart-app', '#shopify-checkout-cart', './components/ui/ShopifyCheckoutCart.ts');
application.registerComponent('roof-calculator', '#roof-calculator', './components/ui/RoofCalculator.ts');

application.init().then(() => {
    console.log('Site is ready.')
    LazySizes.init()
    Alpine.start()
    if (window.htmx !== undefined) {
        htmx.on('htmx:afterSwap', function (event) {
            window.lightbox = GLightbox({})
        });
    }
})
